<template>
  <div>
    <div style="height: 120px"></div>
    <v-container fluid grid-list-xl>
      <v-layout row wrap>
        <v-flex d-flex lg6 sm6 xs12 justify-center font-weight-bold>
          <v-chart :options="starChartBg" :initOptions="{
            width: 1100, height : 500 }" theme='shine'/>
        </v-flex>
        <v-flex d-flex lg6 sm6 xs12 justify-center font-weight-bold>
        </v-flex>
        <v-flex d-flex lg6 sm6 xs12>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>

export default {
  name: 'starChart',
  data (){
    return {
    }
  },
  computed : {
    starChart () {
      return this.$store.state.starChart.chart;
    },
    starChartBg () {
      return this.$store.state.starChart.bgChart;
    }
  }
}
</script>

<style lang="scss" >
@font-face {
  font-family: 'NanumBarunGothic';
  font-style: normal;
  font-weight: 400;
  src: url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.eot');
  src: url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.eot?#iefix') format('embedded-opentype'), url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.woff') format('woff'), url('//cdn.jsdelivr.net/font-nanumlight/1.0/NanumBarunGothicWeb.ttf') format('truetype');
}

small {
  display: block;
  color: #ddd;
  font-size: 16px;
}
.grid__row {
  zoom: 1;
  margin: 0 auto;
  max-width: 92.308em;
}
.grid__row:before,
.grid__row:after {
  display: table;
  content: " ";
}
.grid__row:after {
  clear: both;
}
.centered {
  text-align: center;
}
.content {
  margin: 0 20px;
}
.ion {
  &:hover {
    color: #4f8ef7;
  }
}
.label-image{
  font-size: .7rem;
}
.vue-select-image {
  display: flex;
  justify-content: center;
}
pre,
code {
  text-align: left;
  max-width: 500px;
  margin: auto;
}
table{
  overflow: auto;
  margin: auto;
  margin-top: 0;
  margin-bottom: 16px;
  border-spacing: 0;
  border-collapse: collapse;
  text-align: left;
  font-size: 1.2rem;
  thead{
    tr{
      background-color: #f6f8fa;
    }
  }
  tr {
    background-color: #fff;
    border-top: 1px solid #c6cbd1;
  }
  th, td{
    padding: 6px 13px;
    border: 1px solid #dfe2e5;
  }
}
li > .vue-select-image__thumbnail--active{
  font-family: NanumSquareRound;
  font-weight: bolder;
  font-size: 13px;
  color: #000000;
}
.vue-select-image__thumbnail--active>.vue-select-image__img{
  filter:grayscale(0);
  opacity:1;
  width: 95px;
  height: 95px
}

.custom-style {
  display: flex;
  justify-content: center;
  &__wrapper {
    overflow: auto;
    list-style-image: none;
    list-style-position: outside;
    list-style-type: none;
    padding: 0px;
    margin: 0px;
  }
  &__item {
    margin: 0px 12px 12px 0px;
    float: left;
    @media only screen and (min-width: 1200px) {
      margin-left: 30px;
    }
  }
  &__thumbnail{
    padding: 6px;
    border: 1px solid #dddddd;
    display: block;
    padding: 4px;
    line-height: 20px;
    border: 1px solid #ddd;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    /* -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
    -moz-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055);
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.055); */
    -webkit-transition: all 0.2s ease-in-out;
    -moz-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    &--selected{
      background: #0088cc;
      .custom-style__img{
        zoom: 1.1;
      }
    }
  }
  &__img{
    -webkit-user-drag: none;
    display: block;
    max-width: 100%;
    margin-right: auto;
    margin-left: auto;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
  }
}
.vue-select-image__wrapper{align-items: center;justify-content: center;list-style-type:none;/* padding:0; */margin-bottom: 30px;margin-left: 20px;text-align: center;}.vue-select-image__item{margin:0 0px 30px 0;float:left;width:124.62px;height: 103.73px}.vue-select-image__thumbnail{color:#999; cursor:pointer;display:block;padding:4px;line-height:20px;border:0px solid #ddd;-webkit-border-radius:4px;-moz-border-radius:4px;border-radius:4px;/*-webkit-box-shadow:0 0px 0px rgba(0,0,0,.055);-moz-box-shadow:0 1px 3px rgba(0,0,0,.055);box-shadow:0 1px 3px rgba(0,0,0,.055);*/-webkit-transition:all .2s ease-in-out;-moz-transition:all .2s ease-in-out;-o-transition:all .2s ease-in-out;transition:all .2s ease-in-out}.vue-select-image__thumbnail--selected{background:#08c}.vue-select-image__thumbnail--disabled{background:#b9b9b9;cursor:not-allowed}.vue-select-image__thumbnail--disabled>.vue-select-image__img{opacity:.5}.vue-select-image__img{-webkit-user-drag:none;display:block;margin-right:auto;margin-left:auto;filter:grayscale(3);opacity:0.4;width:95px;height: 95px;justify-content: center;}.vue-select-image__lbl{/*line-height:3;*/font-size: 13px; margin-bottom: 0.3rem;margin-top: 0.3rem;}
.adswrapper {
  width: 70%;
  margin: 0 auto;
  text-align: center;
}
.vue-select-image__thumbnail.is--multiple{box-shadow:unset;}
</style>
